import { bindable, inject }         from 'aurelia-framework';
import { I18N }                     from 'aurelia-i18n';
import { BaseListViewModel }        from 'base-list-view-model';
import { DuplicateModal }           from 'modules/management/specifications/specifications/duplicate-modal';
import { FilterFormSchema }         from 'modules/management/specifications/specifications/filter-form-schema';
import { AppContainer }             from 'resources/services/app-container';
import { Downloader }               from 'resources/services/downloader';
import { SpecificationsRepository } from './services/repository';

@inject(AppContainer, SpecificationsRepository, I18N, Downloader, FilterFormSchema)
export class ListSpecifications extends BaseListViewModel {

    listingId = 'management-specifications-specifications-listing';

    @bindable headerTitle    = 'listing.management.specifications.specifications';
    @bindable newRecordRoute = 'management.specifications.specifications.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     */
    constructor(appContainer, repository, i18N, downloader, filterFormSchema) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.specifications.manage',
            'management.specifications.specifications.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:         this.repository,
            show:               {
                action:  (row) => this.appContainer.router.navigateToRoute('management.specifications.specifications.view', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.specifications.manage', 'management.specifications.specifications.view']),
            },
            edit:               {
                action:  (row) => this.appContainer.router.navigateToRoute('management.specifications.specifications.edit', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.specifications.manage', 'management.specifications.specifications.edit']),
            },
            destroy:            {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.specifications.manage', 'management.specifications.specifications.delete']),
            },
            actions:            [
                {
                    icon:    'icon-copy3',
                    action:  (row) => this.duplicateListing(row),
                    tooltip: 'form.button.duplicate',
                    visible: (row) => true,
                },
            ],
            options:            [
                {
                    label:   'form.button.export-to-excel',
                    icon:    'icon-file-excel',
                    action:  () => this.exportListing(this.i18N.tr('form.field.specification-books'), !this.datatable.instance.selectedRows.length ? null : this.datatable.instance.selectedRows),
                    visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.specifications.manage', 'management.specifications.specifications.view']),
                },
            ],
            selectable:         true,
            destroySelected:    true,
            actionsContextMenu: true,
            sorting:            {
                column:    0,
                direction: 'asc',
            },
            columns:            [
                {
                    data:   'concession',
                    name:   'concessions.acronym',
                    title:  'form.field.concession',
                    hidden: true,
                },
                {
                    data:  'concession',
                    name:  'concession_translations.description',
                    title: 'form.field.concession',
                },
                {
                    data:  'number',
                    name:  'specifications.number',
                    title: 'form.field.number',
                },
                {
                    data:  'code',
                    name:  'specification_revisions.code',
                    title: 'form.field.code',
                },
                {
                    data:  'edition_number',
                    name:  'specification_revisions.edition_number',
                    title: 'form.field.edition-number',
                },
                {
                    data:  'revision_number',
                    name:  'specification_revisions.revision_number',
                    title: 'form.field.revision-number',
                },
                {
                    data:    'observations',
                    name:    'specifications.observations',
                    title:   'form.field.observations',
                    display: false,
                },
                {
                    data:  'created_by',
                    name:  'users.name',
                    title: 'form.field.created-by',
                },
                {
                    data:            'created_at',
                    name:            'specifications.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

    /**
     * Opens the given modal
     */
    openModal(model, viewModel) {
        return this.appContainer.dialogService
            .open({ viewModel: viewModel, model: model })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.datatable.instance.reload();
                }
            });
    }

    duplicateListing(model) {
        return this.appContainer.dialogService.open({
            viewModel: DuplicateModal,
            model:     model,
        }).whenClosed((response) => {
            if (!response.wasCancelled) {
                this.datatable.instance.reload();
            }

            if (response.output !== null) {
                this.appContainer.router.navigateToRoute('management.specifications.specifications.edit', { id: response.output });
            }
        });
    }
}
