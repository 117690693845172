import { DialogController }         from 'aurelia-dialog';
import { bindable, inject }         from 'aurelia-framework';
import { BaseFormViewModel }        from 'base-form-view-model';
import { FormSchema }               from 'modules/management/specifications/specifications/duplicate-modal/form-schema';
import { SpecificationsRepository } from 'modules/management/specifications/specifications/services/repository';
import { AppContainer }             from 'resources/services/app-container';

@inject(AppContainer, SpecificationsRepository, DialogController, FormSchema)
export class DuplicateModal extends BaseFormViewModel {

    formId = 'management.specifications.specifications.creation-form';

    @bindable alert = {};
    model           = {};
    schema          = {};
    create          = true;

    @bindable settings = {
        title:   'form.title.duplicate-record',
        size:    'modal-md',
        buttons: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogController
     * @param formSchema
     */
    constructor(appContainer, repository, dialogController, formSchema) {
        super(appContainer);

        this.repository       = repository;
        this.dialogController = dialogController;
        this.formSchema       = formSchema;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.specifications.manage',
            'management.specifications.specifications.create',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.schema       = this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        this.duplicatingId = params.id;
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.duplicate(this.duplicatingId, this.model)
            .then((response) => {
                this.handleResponse(response, 'management.specifications.specifications.index');
                this.initialModel.assign(this.model);

                if (response.status === true) {
                    this.dialogController.ok(response.model.id);
                }
            })
            .catch((error) => console.error(error));
    }

}
